import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom'

function AuthComponent({children}) {
  const isLogin = localStorage.getItem('access_token') === '13181235283'
  // 随便选的用来验证token是否失效的
  return isLogin === undefined ? <div/>
    : isLogin ? children : <Navigate to='/login'/>
}

export default AuthComponent;