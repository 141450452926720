import './App.css';
import {HashRouter, BrowserRouter} from "react-router-dom";
import SandBox from "./views/SandBox/SandBox";

function App() {
  return (
    <HashRouter>
      <SandBox></SandBox>
    </HashRouter>
  );
}

export default App;
