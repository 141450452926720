import React from 'react';
import {Navigate, useRoutes} from 'react-router-dom'
import AuthComponent from "../components/AuthComponent";
import intl from 'react-intl-universal'

function IndexRouter() {
  return useRoutes([
    // {
    //   path: '/login', element: LazyLoad('Login')
    // },
    {
      path: '/Home', element: LazyLoad('Home')
    },
    // {
    //   path: '/Login', element: LazyLoad('Login')
    // },
    // {
    //   path: '/Personal', element: <AuthComponent>{LazyLoad('Personal')}</AuthComponent>
    // },
    {
      path: '/', element: <Navigate to={'Home'}/>
    },
    {
      path: '*', element: <Navigate to={'Home'}/>
    }
  ])
}


const LazyLoad = (path) => {
  const Comp = React.lazy(() => import(`../views/${path}/${path}`))
  return (<React.Suspense fallback={<>{intl.get('loading...')}</>}>
    <Comp/>
  </React.Suspense>)
}
export default IndexRouter;