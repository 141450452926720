import React, {useEffect, useState} from 'react';
import {ConfigProvider, Image, Layout, Menu, Radio} from 'antd';
import {useNavigate} from 'react-router-dom'
import IndexRouter from "../../router/IndexRouter";

import intl from 'react-intl-universal';
import axios from 'axios';
import headerMenuItems from "../../components/SandBox/HeaderMenuItems";
import en_US from 'antd/es/locale/en_US';
import zh_CN from 'antd/es/locale/zh_CN';
import beian from '../../images/beian.png'

const {Header, Content, Footer} = Layout;

const SUPPOER_LOCALES = [
  {
    name: 'English',
    value: 'en-US'
  },
  {
    name: '简体中文',
    value: 'zh-CN'
  }
];


function SandBox() {
  const navigate = useNavigate()
  const items = headerMenuItems()
  const [initDone, setInitDone] = useState(false);

  const defaultl = navigator.language.toLowerCase();
  let defaultLang = "";
  //  简体中文  这里是赠送部分，因为我需要判断浏览器默认语言，所以整理了一下大概的
  if (
    defaultl === "zh-cn" ||
    defaultl === "zh-chs" ||
    defaultl === "zh-chs-cn" ||
    defaultl === "zh-hans" ||
    defaultl === "zh-hans-cn"
  )
    defaultLang = "zh-CN";
  //  繁体中文：台湾、香港、澳门
  else if (
    defaultl === "zh-tw" ||
    defaultl === "zh-hk" ||
    defaultl === "zh-mo" ||
    defaultl === "zh-cht" ||
    defaultl === "zh-cht-cn" ||
    defaultl === "zh-hant" ||
    defaultl === "zh-hant-cn"
  )
    defaultLang = "zh-CN";
  //  英文：其他
  else
    defaultLang = "en-US";

  const [lang, setLang] = useState(localStorage.getItem("lang") || defaultLang);
  useEffect(() => {
    //  第一次渲染语言的空白
    localStorage.setItem("lang", lang);
  }, [lang]);
  useEffect(() => {
    loadLocales();
  });
  const loadLocales = () => {
    let currentLocale = intl.determineLocale({
      localStorageLocaleKey: "lang",
    });
    axios
      .get(`locales/${currentLocale}.json`)
      .then(res => {
        return intl.init({
          currentLocale,
          locales: {
            [currentLocale]: res.data
          }
        })
      })
      .then(() => {
        setInitDone(true)
      })
  };
  const changeLang = (e) => {
    const newLang = e.target.value
    console.log(newLang)
    if (lang === newLang) return;
    setLang(newLang);
    window.location.reload();
  };

  return (
    initDone && <ConfigProvider locale={lang === 'zh-CN' ? zh_CN : en_US}><Layout>
      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}
      >
        <div className="logo"/>

        <Menu
          theme="dark"
          mode="horizontal"
          // defaultSelectedKeys={['deviceManagement']}
          // selectedKeys={[sideBarMenuKey]}
          // selectedKeys={window.context.router.history.location.pathname]}
          onClick={({key}) => {
            navigate(`/${key}`)
          }}
          items={items}
        />
      </Header>
      <Content
        className="site-layout"
        style={{
          padding: '0 50px',
          marginTop: 64,
        }}
      >
        <div style={{
          textAlign: 'right',
        }}>
          <br/>
          <Radio.Group value={SUPPOER_LOCALES} onChange={changeLang}>
            <Radio.Button key="zh-CN" value={"zh-CN"}>
              中文
            </Radio.Button>
            <Radio.Button key="en-US" value={"en-US"}>
              English
            </Radio.Button>
          </Radio.Group>
        </div>
        <IndexRouter></IndexRouter>
      </Content>
      <Footer
        // style={{
        //   textAlign: 'right',
        // }}
      >
        <div
        //   style={{
        //   width: '300px',
        //   margin: 0,
        //   padding: '20px 0'
        // }}
        >
          <a href="http://beian.miit.gov.cn/" rel="noopener noreferrer" target="_blank">鲁ICP备2021000313号-1</a>
           <br/>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37132502371549"
            rel="noopener noreferrer"
            // style={{display: 'inline-block', textDecoration: 'none', height: '20px', lineHeight: '20px'}}
          >
            <Image
              src={beian}
              alt={''}
            />
            鲁公网安备37132502371549号
          </a>
        </div>
      </Footer>
    </Layout>
    </ConfigProvider>
  );
}

export default SandBox;